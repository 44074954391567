import { z } from "zod";

import { shiftSchema } from "../Shift/types";

const unverifiedShiftSchema = shiftSchema.extend({
  businessrules: z
    .object({
      key: z.string(),
      value: z.string(),
      description: z.string().optional(),
    })
    .optional(),
});
export type UnverifiedShift = z.infer<typeof unverifiedShiftSchema>;

export const unverifiedShiftsResponseSchema = z.object({
  success: z.boolean(),
  response: z.array(unverifiedShiftSchema),
});
export type UnverifiedShiftsResponse = z.infer<typeof unverifiedShiftsResponseSchema>;

export const setShiftTimesheetAvailabilityRequestSchema = z.object({
  shiftId: z.string(),
  reason: z.string(),
  agentName: z.string(),
  agentId: z.string(),
});
export type SetShiftTimesheetAvailabilityRequest = z.infer<
  typeof setShiftTimesheetAvailabilityRequestSchema
>;

export const setShiftTimesheetAvailabilityResponseSchema = z.object({
  success: z.boolean(),
  response: z.object({
    _id: z.string(),
    timecardNotAvailable: z.object({
      reason: z.string(),
      setBy: z.string(),
      updatedAt: z.string(),
    }),
  }),
  error: z.string().nullable(),
});
export type SetShiftTimesheetAvailabilityResponse = z.infer<
  typeof setShiftTimesheetAvailabilityResponseSchema
>;
