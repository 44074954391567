/**
 * This functionality should be in sync with
 *    backend-main/src/utils/payRate.ts (roundUpRateToNearestCent)
 *
 * This function rounds up a pay rate to the nearest cent as defined in product requirements
 * Example:
 *    3.4 hours * $25/hr = $55.277 => $55.28
 *    7.67 hours * $16.5/hr = $126.555 => $126.56
 *    8 hours * $20/hr = $160 => $160.00
 *    6.918 hours * $10/hr = $691.800000000001 => $691.80
 */
export function roundUpRateToNearestCentUsingCbhRounding(rate: number): number {
  const multiplier = 100;
  const fixEpsilonDecimal = (rate * multiplier).toFixed(10);
  return Math.ceil(Number(fixEpsilonDecimal)) / multiplier;
}
