import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface SentTimecardPhotoAnotherWayDialogProps {
  modalState: UseModalState;
}

export function SentTimecardPhotoAnotherWayDialog(props: SentTimecardPhotoAnotherWayDialogProps) {
  const { modalState } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Thank you!"
      actions={
        <Button fullWidth color="primary" variant="text" onClick={modalState.closeModal}>
          OK
        </Button>
      }
    >
      <Text variant="subtitle2">
        We’ll take a look for it. Uploading your timesheet in the app is the quickest way to get
        paid.
      </Text>
    </CommonDialog>
  );
}
