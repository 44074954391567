import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useStreaksEnabledGlobally(): boolean {
  return useCbhFlag(CbhFeatureFlag.STREAKS_ENABLED, {
    defaultValue: false,
  });
}

export function useStreaksEnabledForHcp(): boolean {
  return useCbhFlag(CbhFeatureFlag.HCP_STREAKS_REWARDS, {
    defaultValue: false,
  });
}
