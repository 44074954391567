import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { Image } from "@clipboard-health/ui-react";
import { Button, CircularProgress, Drawer, List, ListItem } from "@mui/material";

interface UploadShiftTimesheetDrawerProps {
  modalState: UseModalState;
  image: Blob;
  isLoading: boolean;
  onUpload: () => any;
}

export function UploadShiftTimesheetDrawer(props: UploadShiftTimesheetDrawerProps) {
  const { modalState, image, isLoading, onUpload } = props;

  return (
    <Drawer
      open={modalState.modalIsOpen}
      anchor="bottom"
      ModalProps={{ keepMounted: false }}
      onClose={modalState.closeModal}
    >
      <List dense>
        <ListItem alignItems="center" sx={{ justifyContent: "center" }}>
          <Title component="h2">Upload shift timesheet</Title>
        </ListItem>
        <ListItem>
          <Image src={URL.createObjectURL(image)} alt="Shift Timesheet" />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={24} /> : undefined}
            onClick={onUpload}
          >
            Submit timesheet
          </Button>
        </ListItem>
        <ListItem>
          <Button fullWidth variant="outlined" disabled={isLoading} onClick={modalState.closeModal}>
            Cancel
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );
}
