import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button, CircularProgress, Drawer, List, ListItem } from "@mui/material";

interface MoreTimesheetOptionsDrawerProps {
  modalState: UseModalState;
  isLoading: boolean;
  onForgotPhoto: () => any;
  onSentAnotherWay: () => any;
  onDidNotWork: () => any;
}

export function MoreTimesheetOptionsDrawer(props: MoreTimesheetOptionsDrawerProps) {
  const { modalState, isLoading, onForgotPhoto, onSentAnotherWay, onDidNotWork } = props;

  const listItems = [
    { onClick: onForgotPhoto, title: "I forgot to take a photo" },
    { onClick: onSentAnotherWay, title: "I sent it to Clipboard another way" },
    { onClick: onDidNotWork, title: "I didn't work this shift" },
  ];

  return (
    <Drawer
      open={modalState.modalIsOpen}
      anchor="bottom"
      ModalProps={{ keepMounted: false }}
      onClose={modalState.closeModal}
    >
      <List dense>
        <ListItem alignItems="center" sx={{ justifyContent: "center" }}>
          <Title component="h2">Why don&apos;t you have a timesheet?</Title>
        </ListItem>
        {listItems.map((listItem) => (
          <ListItem key={listItem.title}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={listItem.onClick}
            >
              {listItem.title}
            </Button>
          </ListItem>
        ))}
        <ListItem>
          <Button fullWidth variant="outlined" disabled={isLoading} onClick={modalState.closeModal}>
            {isLoading ? <CircularProgress size={36} /> : "Cancel"}
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );
}
