import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

export interface UpdateWorkerDepartureStatusRequest {
  agentId: string;
  facilityId: string;
  distanceToFacilityInMiles: number;
}

export function useUpdateWorkerDepartureStatus(
  options: UseMutationOptions<void, AxiosError, UpdateWorkerDepartureStatusRequest> = {}
): UseMutationResult<void, AxiosError, UpdateWorkerDepartureStatusRequest> {
  return useMutation({
    mutationFn: async (data: UpdateWorkerDepartureStatusRequest) => {
      await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/urgentShifts/setWorkerGeofenceUpdate`,
        data,
        responseSchema: z.boolean(),
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.WORKER_DEPARTURE_UPDATE_FAILURE,
    },
    ...options,
  });
}
