import { Title, type UseModalState } from "@clipboard-health/ui-react";
import {
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";

interface CancellationReasonDrawerProps {
  modalState: UseModalState;
  onSubmit: (values: { reason: string; description: string }) => any;
  isLoading: boolean;
  shiftAlreadyStarted: boolean;
  isPossibleCallOff: boolean;
  isPossibleCancellation: boolean;
  shiftStartsInMoreThanADay: boolean;
}

export function CancellationReasonDrawer(props: CancellationReasonDrawerProps) {
  const {
    modalState,
    onSubmit,
    isLoading,
    shiftAlreadyStarted,
    isPossibleCallOff,
    isPossibleCancellation,
    shiftStartsInMoreThanADay,
  } = props;
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");

  const reasons = [
    {
      isShown: shiftAlreadyStarted,
      value: "CAR_BROKE_DOWN",
      label: "Car broke down on my way to the shift",
    },
    {
      isShown: isPossibleCallOff || isPossibleCancellation || shiftStartsInMoreThanADay,
      value: "NO_CAR_FOR_SHIFT",
      label: "Do not have a car to get to shift",
    },
    {
      isShown:
        isPossibleCallOff ||
        isPossibleCancellation ||
        shiftStartsInMoreThanADay ||
        shiftAlreadyStarted,
      value: "FACILITY_ASKING_SWITCH_SHIFTS",
      label: "Facility has asked me to switch shifts",
    },
    {
      isShown: isPossibleCallOff || isPossibleCancellation || shiftStartsInMoreThanADay,
      value: "FACILITY_CANCELLED_SHIFTS",
      label: "Facility cancelled too many shifts",
    },
    {
      isShown:
        isPossibleCallOff ||
        isPossibleCancellation ||
        shiftStartsInMoreThanADay ||
        shiftAlreadyStarted,
      value: "FACILITY_COVID+_STATUS_NOT_UPDATED",
      label: "Facility’s COVID+ status was not updated",
    },
    {
      isShown: shiftAlreadyStarted,
      value: "FEELING_ILL_DURING_SHIFT",
      label: "Started feeling ill during shift",
    },
    {
      isShown: shiftAlreadyStarted,
      value: "DISPLAYING_COVID_SYMPTOMS",
      label: "Displaying COVID symptoms during shift",
    },
    {
      isShown: shiftAlreadyStarted,
      value: "TESTED_COVID+_AT_FACILITY",
      label: "Tested COVID+ at the facility",
    },
    {
      isShown: isPossibleCallOff || isPossibleCancellation || shiftStartsInMoreThanADay,
      value: "TESTED_COVID+",
      label: "Tested COVID+ or displaying symptoms",
    },
    {
      isShown: true,
      value: "SENT_HOME_BY_FACILITY",
      label: "Sent home by facility",
    },
    {
      isShown: isPossibleCallOff || isPossibleCancellation || shiftStartsInMoreThanADay,
      value: "SICK_OTHER_ILLNESS",
      label: "Sick - other illness",
    },
    {
      isShown: true,
      value: "NONE",
      label: "None of the above",
    },
  ];

  return (
    <Drawer
      open={modalState.modalIsOpen}
      anchor="bottom"
      ModalProps={{ keepMounted: false }}
      onClose={modalState.closeModal}
    >
      <List>
        <ListItem alignItems="center" sx={{ justifyContent: "center" }}>
          <Title component="h2">Select a cancellation reason:</Title>
        </ListItem>
        <ListItem>
          <FormControl required disabled={isLoading}>
            <RadioGroup
              value={reason}
              onChange={(event) => {
                setReason(event.target.value);
              }}
            >
              {reasons.map(
                (reasonOptions) =>
                  reasonOptions.isShown && (
                    <FormControlLabel
                      key={reasonOptions.value}
                      value={reasonOptions.value}
                      label={reasonOptions.label}
                      control={<Radio />}
                    />
                  )
              )}
            </RadioGroup>
          </FormControl>
        </ListItem>
        {reason && (
          <ListItem>
            <TextField
              fullWidth
              multiline
              required
              label="Tell us what happened"
              value={description}
              disabled={isLoading}
              id="description"
              variant="outlined"
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </ListItem>
        )}
        <ListItem>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!reason || !description || isLoading}
            startIcon={isLoading ? <CircularProgress size={24} /> : undefined}
            onClick={() => {
              onSubmit({ reason, description });
            }}
          >
            Cancel shift
          </Button>
        </ListItem>
        <ListItem>
          <Button fullWidth variant="outlined" disabled={isLoading} onClick={modalState.closeModal}>
            Close
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );
}
