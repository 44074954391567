import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { InternalLink } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { CommonDialog } from "@src/appV2/lib";

interface NonInstantPayShiftDialogProps {
  modalState: UseModalState;
  userHasForgottenToClockOut?: boolean;
}

export function NonInstantPayShiftDialog(props: NonInstantPayShiftDialogProps) {
  const { modalState, userHasForgottenToClockOut } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="This shift is no longer InstantPay-eligible."
      actions={
        <Button fullWidth color="primary" variant="text" onClick={modalState.closeModal}>
          OK
        </Button>
      }
    >
      {userHasForgottenToClockOut ? (
        <Text variant="subtitle2">Looks like you forgot to clock out!</Text>
      ) : null}
      <Text variant="subtitle2">Once the facility verifies the shift, you will be paid.</Text>
      <Text variant="subtitle2" marginTop={1}>
        If you have any questions, please visit the{" "}
        <InternalLink to={DeprecatedGlobalAppV1Paths.NEED_HELP}>Help Center</InternalLink>.
      </Text>
      {userHasForgottenToClockOut ? (
        <Text variant="subtitle2" marginTop={1}>
          Next time, remember to clock out of your shift within two hours of the end time to remain
          eligible for InstantPay.
        </Text>
      ) : null}
    </CommonDialog>
  );
}
