import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import {
  convertToLegacyGeoLocationCoordinates,
  type DeviceGeoLocation,
  getDeviceGeoLocationIfAllowed,
} from "@src/appV2/Location";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { shiftSchema } from "../../Shift/types";

const urgentShiftsRequestSchema = z.object({
  coordinates: z.number().array().optional(),
  /**
   * Note that this is a negative filter. It really should say what should be included
   * such as `includeShifts`.
   */
  getCountOnly: z.boolean(),
});
export type UrgentShiftsRequest = z.infer<typeof urgentShiftsRequestSchema>;

const urgentShiftSchema = shiftSchema.extend({
  /**
   * FIXME: Investigate the server for this `_id` object that
   * carries extra data. We don't use it, and one would typically expect
   * that the `_id` is a string.
   * We assume this is a string everywhere, but it is not a string from the server
  _id: z.object({
    facilityId: z.string(),
    start: z.string(),
    end: z.string(),
    bookable: z.boolean(),
    urgency: z.number(),
  }),
   */
  _id: z.any(),
});
export type UrgentShift = z.infer<typeof urgentShiftSchema>;

const urgentShiftsResponseSchema = z.object({
  shifts: z.array(urgentShiftSchema).optional(),
  total: z.number(),
});
export type UrgentShiftsResponse = z.infer<typeof urgentShiftsResponseSchema>;

export function useUrgentShifts(
  queryParams: UrgentShiftsRequest,
  options: UseQueryOptions<UrgentShiftsResponse> = {}
): UseQueryResult<UrgentShiftsResponse> {
  const url = `${environmentConfig.REACT_APP_BFF_URL}/urgent-shifts`;
  return useQuery({
    queryKey: [url, queryParams],
    queryFn: async () => {
      const { coordinates } = queryParams;
      let deviceGeoLocation: DeviceGeoLocation | undefined;
      try {
        deviceGeoLocation = await getDeviceGeoLocationIfAllowed();
      } catch (error) {
        // Ignore and fall through
        logError(APP_V2_APP_EVENTS.GET_DEVICE_GEOLOCATION_IF_ALLOWED_FAILURE, { error });
      }

      const legacyAgentCoordinates = deviceGeoLocation?.geoLocation
        ? convertToLegacyGeoLocationCoordinates(deviceGeoLocation?.geoLocation)
        : coordinates;
      const response = await get({
        url,
        queryParams: {
          ...queryParams,
          coordinates: legacyAgentCoordinates,
        },
        requestSchema: urgentShiftsRequestSchema,
        responseSchema: urgentShiftsResponseSchema,
      });
      return response.data;
    },
    ...options,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_URGENT_SHIFTS_FAILURE,
      ...options.meta,
    },
  });
}
