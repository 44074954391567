import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, Drawer, InputBase, List, ListItem } from "@mui/material";
import { chooseFromLibrary, takePhoto } from "@src/appV2/lib/files";
import { useRef } from "react";

interface PickShiftTimesheetDrawerProps {
  modalState: UseModalState;
  onImagePicked: (image: Blob) => void;
}

export function PickShiftTimesheetDrawer(props: PickShiftTimesheetDrawerProps) {
  const { modalState, onImagePicked } = props;
  const inputFileRef = useRef<HTMLInputElement>();

  return (
    <Drawer
      open={modalState.modalIsOpen}
      anchor="bottom"
      ModalProps={{ keepMounted: false }}
      onClose={modalState.closeModal}
    >
      <List dense>
        <ListItem alignItems="center" sx={{ justifyContent: "center" }}>
          <Title component="h2">Upload shift timesheet</Title>
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={async () => {
              if (isDefined(inputFileRef.current)) {
                const image = await takePhoto(inputFileRef.current);
                if (isDefined(image)) {
                  onImagePicked(image);
                }
              }
            }}
          >
            Take photo
          </Button>
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={async () => {
              if (isDefined(inputFileRef.current)) {
                const image = await chooseFromLibrary(inputFileRef.current);
                if (isDefined(image)) {
                  onImagePicked(image);
                }
              }
            }}
          >
            Choose from library
          </Button>
        </ListItem>
        <ListItem>
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Cancel
          </Button>
        </ListItem>
      </List>
      <InputBase
        inputRef={inputFileRef}
        type="file"
        inputProps={{ accept: "image/*", capture: true }}
        sx={{ display: "none" }}
      />
    </Drawer>
  );
}
