/**
 * This functionality should be in sync with
 *    backend-main/src/utils/timeCalc.ts (roundUpHoursTwoDecimal)
 *
 * This function rounds up a shift time as defined in product requirements
 * Example:
 *    (3 hours, 40 minutes) 3.6666666 => 3.67
 *    (1 hour, 30 minutes) 1.5 => 1.5
 *    (20 minutes) 0.3333333 => 0.34
 */
export function roundUpHoursToTwoDecimalsUsingCbhRounding(rawTimeInHours: number): number {
  const multiplier = 100;
  const fixEpsilonDecimal = (rawTimeInHours * multiplier).toFixed(2);
  return Math.ceil(Number(fixEpsilonDecimal)) / multiplier;
}
