import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface ForgotTimecardPhotoDialogProps {
  modalState: UseModalState;
}

export function ForgotTimecardPhotoDialog(props: ForgotTimecardPhotoDialogProps) {
  const { modalState } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Thanks for letting us know!"
      actions={
        <Button fullWidth color="primary" variant="text" onClick={modalState.closeModal}>
          OK
        </Button>
      }
    >
      <Text variant="subtitle2">
        In order to get paid quickly, we require an uploaded timesheet. Without a timesheet, it can
        take up to a week to verify your shift.
      </Text>
    </CommonDialog>
  );
}
