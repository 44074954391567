import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface ConfirmReuploadTimecardDialogProps {
  modalState: UseModalState;
  onConfirm: () => any;
}

export function ConfirmReuploadTimecardDialog(props: ConfirmReuploadTimecardDialogProps) {
  const { modalState, onConfirm } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Are you sure you want to upload a new timesheet?"
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            Reupload
          </Button>
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Cancel
          </Button>
        </>
      }
    >
      <Text variant="body2">The old one will be replaced and can&apos;t be retrieved anymore.</Text>
    </CommonDialog>
  );
}
